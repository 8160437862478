/**
 * @license
 * Copyright© Celligence International, LLC - 2023
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
*/
import React, { useState, useEffect, Suspense, lazy } from "react";
import Carousel from "react-spring-3d-carousel-2";
import { config } from "react-spring";
import { Container, Col, Row } from "react-bootstrap";
import axios from "axios";
import rightarrow from "./Images/rightarrow.png";
import leftarrow from "./Images/leftarrow.png";
import { StyledModal } from "../components/Video/styles";
import styled from "styled-components";
import { data } from "browserslist";
import "../css/videocarousel.css";
const Video = lazy(() => import("../components/Video/video.js"));

export default function SocialMediaPosts(props) {
  let slides = [];
  const imgCDNURL = process.env.REACT_APP_USDA_LOANS_CDN_URL;
  const [BannerPics, setBannerPics] = useState([]);
  const [play, setPlay] = useState(false);
  const [url, setUrl] = useState("");
  const videoToggle = (play, url) => {
    setPlay(!play);
    setUrl(url);
  };
  const audio = new Audio();
  audio.src = process.env.REACT_APP_CLICK_SOUND;

  const [state, setState] = useState({
    goToSlide: 0,
    offsetRadius: 4,
    showNavigation: false,
    config: config.gentle,
    draggable: true,
  });

  // const onChangeInput = (e) => {
  //   setState({
  //     [e.target.name]: parseInt(e.target.value, 10) || 0,
  //   });
  // };

  let xDown = null;
  let yDown = null;

  const getTouches = (evt) => {
    return (
      evt.touches || evt.originalEvent.touches // browser API
    ); // jQuery
  };

  const handleTouchStart = (evt) => {
    const firstTouch = getTouches(evt)[0];
    xDown = firstTouch.clientX;
    yDown = firstTouch.clientY;
    audio.play();
  };

  const handleTouchMove = (evt) => {
    if (!xDown || !yDown) {
      return;
    }

    let xUp = evt.touches[0].clientX;
    let yUp = evt.touches[0].clientY;

    let xDiff = xDown - xUp;
    let yDiff = yDown - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      /*most significant*/
      if (xDiff > 0) {
        /* left swipe */
        setState({ goToSlide: state.goToSlide + 1, offsetRadius: 4 });
      } else {
        /* right swipe */
        setState({ goToSlide: state.goToSlide - 1, offsetRadius: 4 });
      }
    } else {
      if (yDiff > 0) {
        /* up swipe */
      } else {
        /* down swipe */
      }
    }
    audio.play();
    /* reset values */
    xDown = null;
    yDown = null;
  };

  useEffect(() => {
    // Get the event pics from a JSON file hosted in the cdn
    async function fetchBannerPics() {
      try {
        const memoryPics = await axios.get(
          process.env.REACT_APP_USDA_LOANS_JSON_URL
        );
        // console.log(memoryPics.data);
        let memoryPicsData = memoryPics.data;
        for (var data of memoryPicsData) {
          slides.push({
            key: data.key,
            content: (
               <a href={data.url} target="_blank">
              <img src={imgCDNURL + data.thumbnail} className="postimages"  />
              </a>
            ),
          });
        }
        // console.log("slides: " + JSON.stringify(slides));
        let BannerPicsData = slides.map((slide, index) => {
          return {
            ...slide,
            onClick: () => setState({ goToSlide: index, offsetRadius: 4 }),
          };
        });
        setBannerPics(BannerPicsData);
      } catch (err) {
        console.log(err);
      }
    }
    fetchBannerPics();
  }, []);

  return (
    <>
    <div>
      <div class="top-section">

            {props.title && <h2>{props.title}</h2>}
          
          </div>
      <Container>
        <Row>
          <Col lg={12} xl={12} md={12} sm={12}>
            <div
              className="posts"
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
            >
              <Carousel
                slides={BannerPics} 
                autoPlay
                draggable={state.draggable}
                goToSlide={state.goToSlide}
                offsetRadius={state.offsetRadius}
                showNavigation={state.showNavigation}
                animationConfig={state.config}
                goToSlideDelay="20000"
                interval={20}
              />
            </div>
            <div>
              <img
                src={leftarrow}
                className="leftarrow"
                alt="leftnavigation"
                onClick={() => {
                  setState({ goToSlide: state.goToSlide - 1, offsetRadius: 4 });
                  audio.play();
                }}
              />

              <img
                src={rightarrow}
                className="rightarrow"
                alt="rightnavigation"
                onClick={() => {
                  setState({ goToSlide: state.goToSlide + 1, offsetRadius: 4 });
                  audio.play();
                }}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    <Suspense fallback={<div />}>
    <StyledModal show={play}>
      <Video onClose={() => videoToggle(play, '')} url={url} />
    </StyledModal>
    </Suspense>
    </>
  );
  
}
